@import "../../App.scss";

.container {
	text-align: center;
	display: flex;
	flex-direction: column;

	#smaller {
		padding: 4rem 1.125rem 1rem;
		margin-top: 3rem;

		@include desktop {
			display: none;
		}
	}
	#desktop {
		display: none;
	}
	@include desktop {
		#desktop {
			padding: 3rem;
			margin: 5rem 4rem 2rem;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			.text-holder {
				text-align: start;
				width: 40%;
				min-width: 350px;
				max-width: 480px;
				margin: 2rem;
			}

			.img-holder {
				margin: 3rem;
			}
		}
	}

	h1 {
		font-weight: 600;
		font-family: "Domine", serif;
		font-size: 1.375rem;
		color: $text-color;
		margin-bottom: -20px;
	}
	.slide-top {
		animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
	}
	.primary-color {
		color: $primary-color;
	}
	.text-holder {
		@include tablet {
			width: 70%;
			margin: 0 auto;
		}
		p {
			margin: 2.5rem 0;
			line-height: 1.6rem;
			color: $text-color;
		}
		.link {
			color: $primary-color;
		}
		.highligt {
			font-weight: 500;
		}
	}
	/**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  **/
	@keyframes slide-top {
		0% {
			transform: translateY(-40);
			opacity: 0;
		}
		100% {
			transform: translateY(-40px);
			opacity: 100;
		}
	}
}
