// ** Media queries **
@mixin tablet {
  @media only screen and (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: $desktop-width) {
    @content;
  }
}
